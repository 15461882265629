import { initialize, LDClient, LDUser } from 'launchdarkly-js-client-sdk';
import { UserService } from '../user/user.service';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagService implements OnDestroy {
    /**
     * BehaviourSubject to update the feature flags
     *  - Updated in [handleClientEvents]{@link FeatureFlagService#handleClientEvents} with the flags
     * - Subscribed to in [checkFeatureFlag]{@link FeatureFlagService#checkFeatureFlag} to get the values of the current flags
     */
    private featureFlags$ = new BehaviorSubject<{
        [key: string]: boolean;
    } | null>(null);

    /**
     * Launch Darkly client
     * - Used to initialize the client in the [setUpLDClient]{@link FeatureFlagService#setUpLDClient} function
     *  - Used to handle the client events in the [handleClientEvents]{@link FeatureFlagService#handleClientEvents} function (ready, failed, error)
     *  - Used to set the flag status in the [checkFeatureFlag]{@link FeatureFlagService#checkFeatureFlag} function if there are no flags set
     */
    private ldClient: LDClient;

    /**
     * ReplaySubject to broadcast that the LaunchDarkly client event is ready
     *  - Set in the [handleClientEvents]{@link FeatureFlagService#handleClientEvents} function
     *  - Subscribed to in the chat component in [ngOnInit()]{@link ChatComponent#ngOnInit}.
     */
    public broadcasts = new ReplaySubject<string>();

    /**
     * Subject to unsubscribe from all subscriptions
     *  - Used in the [ngOnDestroy]{@link FeatureFlagService¢#ngOnDestroy} function to unsubscribe from all subscriptions
     */
    unsubscribe$ = new Subject();

    /**
     * Account object for the Launch Darkly client
     *  - Set in the constructor using the [createAccountObject]{@link FeatureFlagService#createAccountObject} function
     *  - Used to initialize the Launch Darkly client in the [setUpLDClient]{@link FeatureFlagService#setUpLDClient} function
     *  - Sets what Launch Darkly uses to check if a flag is active or not for a specific user (key)
     */
    account: any = { key: '' };

    /**
     * [createAccountObject]{@link FeatureFlagService#createAccountObject} called to set the account
     * - [setUpLDClient]{@link FeatureFlagService#setUpLDClient} called to set the Launch Darkly client
     * - [handleClientEvents]{@link FeatureFlagService#handleClientEvents} called
     */
    constructor(private userService: UserService) {
        const account = this.createAccountObject();

        this.ldClient = this.setUpLDClient(account);
        this.handleClientEvents();
    }

    /**
     * When the component is destroyed, unsubscribe from all subscriptions using the [unsubscribe$]{@link FeatureFlagService#unsubscribe$} Subject
     */
    ngOnDestroy(): void {
        this.unsubscribe$.next('');
        this.unsubscribe$.complete();
    }

    /**
     * Sets up the Launch Darkly client, with the key and the account information, if there isn't one initialised, or returns the current client if it exists
     *  - Called in the constructor
     * @param account
     * @returns the Launch Darkly client
     */
    setUpLDClient(account: any) {
        let ldClient: LDClient;
        if (!this.ldClient) {
            ldClient = initialize('6706506fbc58c5080dc35d0c', account, {
                sendEvents: true,
            });
            return ldClient;
        } else return this.ldClient;
    }

    /**
     * Handles the Launch Darkly client events (ready, failed, error)
     *  - When the client is ready, it updates the [featureFlags$]{@link FeatureFlagService#featureFlags$} with the flags - This MUST be done before the broadcast
     *  - Broadcasts that the client is ready
     *  - If the client fails, it logs the data
     *  - If the client errors, it logs the data
     *  - Called in the constructor
     */
    handleClientEvents() {
        this.ldClient.on('ready', () => {
            this.featureFlags$.next(this.ldClient.allFlags());
            this.broadcasts.next('ready');
        });

        this.ldClient.on('failed', (data: any) => {
            console.error('launch darkly client failed: ', data);
        });

        this.ldClient.on('error', (data: any) => {
            console.error('launch darkly client error: ', data);
        });
    }

    /**
     * Subscribes to [userDetails]{@link UserService#userDetails} in the UserService to get the user details, which are then used to determine which key the account object should have
     *  - If the email includes 'voxpopme.com', the key is set to the email
     * - If the email does not include 'voxpopme.com' or the userName is 'Katie-voxpopme', the key is set to 'interviewroom@voxpopme.com'
     *  - Called in the constructor
     * @returns the account object for the Launch Darkly client
     */
    createAccountObject(): LDUser {
        let key: any;
        this.userService.userDetails
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((userDetails) => {
                if (userDetails?.email?.includes('voxpopme.com')) {
                    key = userDetails.email;
                } else if (
                    (userDetails.email &&
                        !userDetails.email.includes('voxpopme.com')) ||
                    userDetails.userName === 'Katie-voxpopme'
                ) {
                    key = 'interviewroom@voxpopme.com';
                } else if (userDetails.userName === 'VpmObs') {
                    key = 'testObserver';
                }

                this.account = {
                    key: key,
                };
            });

        return this.account;
    }

    /**
     * Gets the feature flags from the BehaviorSubject and checks if the flag exists in the object. If they don't it calls the Launch Darkly client to check the flag
     *  - Called in [setUpFeatureFlags]{@link ChatComponent#setUpFeatureFlags} in the ChatComponent to get the feature flags
     * @param flag
     * @returns the flagStatus
     */
    checkFeatureFlag(flag: string): boolean {
        let flagStatus = false;

        const currentFlags = this.featureFlags$.getValue();

        if (currentFlags && flag in currentFlags) {
            flagStatus = currentFlags[flag];
        } else {
            flagStatus = this.ldClient.variation(flag);
        }

        return flagStatus;
    }
}
